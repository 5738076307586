import Logo from "../../Imagenes/Logo.jpg";
import InstaBoton from "../../componentes/InstaBoton/InstaBoton";
import Seo from "../../componentes/Seo/Seo";
import WhatsBoton from "../../componentes/WhatsBoton/WhatsBoton";
import "./cursos.css"

const Cursos = () => {
  return (
    <>
    <Seo title={"Lole Sier | Cosmiatra Hospitalaria | Cursos"} description={"Lole Sier es un espacio dedicado a la salud y belleza de tu rostro. Ofrecemos tratamientos de limpieza de cutis, peeling, radiofrecuencia, entre otros."} keywords={"Lole Sier Cosmiatra, cosmetologa, cosmiatra, acne, belleza, cutis, electroporacion, facial, limpieza, peeling, radiofrecuencia, tratamientos"} rel={""} url={""}></Seo>
    <div className="contenedorCursos">
      <div className="bodyCursos">
        <img src={Logo} className="imagen-curso" alt="logo" />
        <h2 className="texto-cursos">¡¡Estoy Preparando el Curso que Vos Necesitas!!</h2>
      </div>
    </div>
    <InstaBoton/>
    <WhatsBoton/>
    </>
  );
};

export default Cursos;
