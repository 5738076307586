import Seo from "../../componentes/Seo/Seo";
import video from "../../Imagenes/video.mp4"
import Logo4 from "../../Imagenes/Logo4.png";
import "./home.css";

const Home = () => {
  return (
    <>
    <Seo title={"Lole Sier | Cosmiatra Hospitalaria |"} description={"Lole Sier es un espacio dedicado a la salud y belleza de tu rostro. Ofrecemos tratamientos de limpieza de cutis, peeling, radiofrecuencia, entre otros."} keywords={"Lole Sier Cosmiatra, cosmetologa, cosmiatra, acne, belleza, cutis, electroporacion, facial, limpieza, peeling, radiofrecuencia, tratamientos"} rel={"canonical"} url={"https://lolesier.com.ar/"}></Seo>
    <div className="contenedorMain">
      <video autoPlay muted loop playsInline className="video" title="Masaje facial">
        <source src={video} type="video/mp4"/>
      </video>
      <img src={Logo4} alt="Logo Lole Sier" className="imagenHome" title="Logo Lole Sier"/>
      <h1 className="parrafoHome">COSMIATRA</h1>
    </div>    
    </>
  );
};

export default Home;
