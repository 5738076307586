import CardData from "../../componentes/CardData/CardData";
import Seo from "../../componentes/Seo/Seo";
import WhatsBoton from "../../componentes/WhatsBoton/WhatsBoton";
import "./tratamientos.css";

const Tratamientos = () => {
  return (
    <>
    <Seo title={"Lole Sier | Cosmiatra Hospitalaria | Tratamientos"} description={"Lole Sier es un espacio dedicado a la salud y belleza de tu rostro. Ofrecemos tratamientos de limpieza de cutis, peeling, radiofrecuencia, entre otros."} keywords={"Lole Sier Cosmiatra, cosmetologa, cosmiatra, acne, belleza, cutis, electroporacion, facial, limpieza, peeling, radiofrecuencia, tratamientos"} rel={""} url={""}></Seo>
    <div className="tratamientosMain">
      <section className="contenedor-tratamientos">
        <CardData/>
      </section>
        <WhatsBoton/>
    </div>
    </>
  );
};

export default Tratamientos;
