import { Helmet } from "react-helmet-async";
import Logo from "../../Imagenes/Logo.jpg";

const Seo = ({title, description, keywords, rel, url}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="keywords" content={keywords}/>
      <meta name="author" content="Lole Sier"/>
      <meta property="og:title" content={title}/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={window.location.href}/>
      <meta property="og:image" content={Logo}/>
      <link rel={rel} href={url} />
    </Helmet>
  )
}

export default Seo;