export const tratamientos = [
  {
    id: 1,
    titulo: "Higiene Profunda Express",
    encabezado: "Tratamiento personalizado según el tipo de piel. Ideal para pieles desvitalizadas que necesitan extracciones, equilibrar su barrera y un shock de luminosidad.",
    incluye: `Incluye:
    - Doble Higiene
    - Mascarilla enzimatica/queratolítica con espátula ultrasónica 
    - Extracciones 
    - Mascarilla descongestiva 
    - FPS 50+ 
    Duración: 60 min`
  },
  {
    id: 2,
    titulo: "Higiene Profunda Premium",
    encabezado: "Tratamiento personalizado según el tipo de piel.Ideal para pieles desvitalizadas que necesitan extracciones, equilibrar su barrera y un shock de luminosidad.",
    incluye: `Incluye: 
    -Doble Higiene
    -Mascarilla enzimatica/queratolítica con espátula ultrasónica
    -Peeling acorde Meso virtual con blend de activos
    -Mascarilla descongestiva
    -¡¡Y AQUI ES DONDE SUCEDE LA MAGIA!! Masaje bioenergético relajante. Un masaje drenante facial con Gua Sha, aromaterapia y masaje capilar
    -FPS 50+
    Duración: 90 min`
  },
  {
    id: 3,
    titulo: "Dermaplaning Premium",
    encabezado: "Tratamiento personalizado según el tipo de piel.Procedimiento de exfoliación física realizado con bisturí que elimina 2/3 partes de células muertas, vello y detritus acumulados en la piel. Brinda al rostro una gran luminosidad y potencia la rutina diaria",
    incluye: `Incluye:
    -Doble Higiene
    -Perfilado facial con bisturí
    -Peeling acorde
    -Meso virtual con blend de activos
    -Masajes faciales
    -Mascarilla descongestiva
    -Masaje bioenergético relajante. Un masaje drenante facial con Gua Sha, aromaterapia y masaje capilar
    -FPS 50+
    Duración: 90 min`
  },
  {
    id: 4,
    titulo: "Microneedling Orthomolecular",
    encabezado: "Es la terapia de inducción al colágeno y elastina. Es un procedimiento que estimula la capacidad de regeneración cutánea a traves de múltiples micropunciones, desencadenando la generación natural del colágeno y elastina, a la vez que permite una penetración más profunda de los activos.",
    incluye: `Incluye:
    -Perfilado facial (dermaplaning, si la piel lo permite)
    -Peeling químico acorde
    -Dermapen Blend de activos
    -Mascarilla descongestiva Masaje bioenergético relajante. Un masaje drenante facial con Gua Sha, aromaterapia y masaje capilar
    -FPS 50+
    Duración: 90 min`
  },
  {
    id: 5,
    titulo: "Microneedling Antiage",
    encabezado: "Es la terapia de inducción al colágeno y elastina a través de micropuntura con dispositivo Dermapen. Reduce arrugas finas, disminuye poros visibles, aporta luminosidad y trata cicatrices post acné y estrías. Se utiliza ampolla de péptidos, aligopéptidos y células madre. Reparan y reactivan estructuras celulares.",
    incluye: `Incluye:
    -Doble Higiene
    -Perfilado facial (dermaplaning, si la piel lo permite)
    -Peeling químico acorde
    -Dermapen con ampolla biológica Blend de activos
    -Mascarilla descongestiva
    -Masaje bioenergético relajante. Un masaje drenante facial con Gua Sha, aromaterapia y masaje capilar
    -FPS 50+
    Duración: 90 min`
  },
  {
    id: 6,
    titulo: "PB Serum Renewal Equilibrium",
    encabezado: "Tratamiento importado premium, destinado a renovar la piel mediante enzimas recombinantes que actuan en el tejido para estimular la producción de colágeno y elastina. Este tratamiento combina la enzima Queratinasa con efecto smart peeling, vit A, vit C y ácido hialurónico. Mejora la textura de la piel, crono envejecimiento, arrugas, tamaño de los poros, brotes de acné, manchas y cicatrices de acné.",
    incluye: `Incluye:
    -Doble Higiene
    -Dermaplaning
    -Peeling químico acorde
    -Ampolla recombinante (en rostro, cuello y escote)
    -Mascarilla oclusiva
    -Masaje bioenergético relajante. Un masaje drenante facial con Gua Sha, aromaterapia y masaje capilar
    -FPS 50+
    Duración: 90 min`
  },
  {
    id: 7,
    titulo: "PB Serum Radiant",
    encabezado: "Tratamiento importado premium, destinado a renovar la piel mediante enzimas recombinantes que actuan en el tejido para estimular la producción de colágeno y elastina. Este tratamiento combina la enzima Queratinasa con efecto smart peeling con vit C al 20% y ácido hialurónico, que hace de este tratamiento una combinación de activos antioxidantes, despigmentantes y antiage.",
    incluye: `Incluye:
    -Doble Higiene
    -Dermaplaning
    -Peeling químico despigmentante y antioxidante
    -Dermapen con ampolla de enzima
    -Mascarilla oclusiva
    -Masaje bioenergético relajante. Un masaje drenante facial con Gua Sha, aromaterapia y masaje capilar
    -FPS 50+
    Duración: 90 min`
  },
  {
    id: 8,
    titulo: "Masaje de Lifting Facial Japones",
    encabezado: "El Lifting Facial Japones recupera y mantiene la belleza de forma totalmente natural. ¿Que es lo que vas a ver desde la primera sesión? ",
    incluye: `Mediante técnicas de amasamiento, verás cambios estéticos visibles, rejuvenece, tensa los tejidos, activa la circulación, ilumina y tonifica la piel, rellena la dermis y elimina toxinas e impurezas. Equilibra el estado físico, mental y espiritual mediante la combinación del masaje y la energía. ¿Quienes pueden recibirlo? Todos aquellos que deseen obtener beneficios en términos de bienestar, relajación, equilibrio y mantenimiento del tono muscular en solo una sesión.
    Duración: 60 min`
  },
  {
    id: 9,
    titulo: "Reflexología Cráneo Facial",
    encabezado: "Es una masaje suave y profundo que se aplica en el cráneo y rostro para estimular ciertas zonas reflejas y puntos fijos que conectan con nuestro organismo Es un tratamiento que puede adicionarse a cualquier facial.",
    incluye: `Beneficios estéticos:
    Disminuir imperfecciones de la piel, activación del sistema linfático, oxigenación de la piel, combate efectos de la fatiga y el cansancio, reduce lineas de expresión y define los contornos, es hidratante.
    Efectos emocionales de la reflexología:
    Conseguimos un efecto relajante y descontracturante liberando tensiones en grandes superficies del cuerpo. Ayuda a combatir el insomnio.
    Ventajas facioterapia Dien-Chan y salud:
    Relaja el sistema nervioso, activa la circulación sanguínea, favorece los tratamientos de desintoxicación y reequilibrio del organismo, libera tensiones y contracciones musculares, desaparecen los dolores de cabeza y migrañas.
    Duración: 40 min`
  },
  {
    id: 10,
    titulo: "Radiofrecuencia enzimatica by Sveltia",
    encabezado: "Es mi tratamiento estrella y tensor por excelencia es la radiofrecuencia enzimática donde combinamos colagenasa + Sveltia",
    incluye: `¿Qué es la colagenasa? Es una enzima de origen biológico altamente eficaz que degrada el Colágeno viejo para formar Colágeno nuevo alisando la piel de forma inmediata y duradera.

EN QUÉ CONSISTE EL TRATAMIENTO?

Limpieza del rostro, aplicación de la enzima con masajes, aplicación de Radiofrecuencia multipolar con nuestro equipo de última tecnología Sveltia Frax, combinación 100% placentera

VENTAJAS DE ESTE TRATAMIENTO

Es un tratamiento ideal para aquellas personas que buscan mejorar la flacidez de su rostro, devolviéndole luminosidad natural a la piel.

Se realiza en rostro, cuello y escote.

Duración 60 min`
  },
  {
    id: 11,
    titulo: "Dermaglow",
    encabezado: "Es un tratamiento ideal para aquellas personas que buscan mejorar la flacidez de su rostro, devolviéndole luminosidad natural a la piel.",
    incluye: `Cicatrices de acné, moderación y tensado cutáneo....

Tratamiento que combina la técnica de Dermaplaning (perfilado facial), con Radiofrecuencia Multipolar by Sveltia

EN QUÉ CONSISTE EL TRATAMIENTO?

Doble Higiene, Dermaplaning, peeling enzimático, mascarilla con activos antiage aptos Radiofrecuencia, aplicación de Radiofrecuencia multipolar con nuestro equipo de última tecnología Sveltia Frax, combinación 100% placentera

Duración 60 min`
  },
]